body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #95d1c4;
  color: #1f2933;
  font-size: 14px;
}

a {
  font-weight: 700;
  text-decoration: underline;
  color: #1f2933;
}

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  /* Added */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}

[data-reach-dialog-content] {
  outline: none;
}
